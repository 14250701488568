<template>
  <footer class="footer">
    <div class="container _small-width">
      <div class="footer-nav">
        <a
          v-for="(doc, i) in docs"
          :key="i"
          :href="`${publicPath}docs/${doc.path}`"
          class="footer-nav__item"
          target="_blank"
        >
          {{ doc.name }}
        </a>
        <div
          class="footer-nav__item"
          @click="setMetrika('question'), $modal.show('feedback')"
        >
          обратная связь
        </div>
      </div>
      <div class="footer__text">
          Для участия в&nbsp;акции необходимо: в&nbsp;период с&nbsp;24&nbsp;сентября по&nbsp;29&nbsp;октября 2024&nbsp;г. приобрести в&nbsp;магазинах торговой сети &laquo;Глобус&raquo; или в&nbsp;интернет-магазине &laquo;Глобус&raquo; любой кофе Jardin и&nbsp;зарегистрировать чек с&nbsp;покупкой на&nbsp;сайте <a
            href="https://jardin-globus.ru"
            class="_white _undeline-link"
            target="_blank"
            >jardin-globus.ru</a
          >. Общий срок проведения акции (включая срок выдачи призов): с&nbsp;24&nbsp;сентября по&nbsp;30&nbsp;ноября 2024&nbsp;г. Количество призов ограничено. Призы могут отличаться от&nbsp;изображений на&nbsp;рекламных материалах. Информация об&nbsp;организаторе акции, сроках и&nbsp;правилах ее&nbsp;проведения, количестве призов, сроках, месте и&nbsp;порядке вручения призов размещена на&nbsp;сайте <a
            href="https://jardin-globus.ru"
            class="_white _undeline-link"
            target="_blank"
            >jardin-globus.ru</a
          >.
      </div>
      <img src="../assets/images/logo.svg" alt="" class="footer__logo" />
      <!-- <div class="footer__copyright">
        © <span>{{ year }}</span> Все права защищены.
      </div> -->
    </div>
  </footer>
</template>
<script>
export default {
  props: ["partners"],
  data() {
    return {
      publicPath: process.env.BASE_URL,
      docs: [
        {
          name: "правила акции",
          path: "rules.pdf",
        },
        {
          name: "политика конфиденциальности",
          path: "policy.pdf",
        },
      ],
    };
  },
  methods: {
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  },
  computed: {
    year() {
      let date = new Date();
      return date.getFullYear();
    },
  },
};
</script>
<style scoped lang="scss">
.footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: rem(20px) 0 rem(125px);
  border-bottom: none;
  text-align: justify;
  z-index: 10;
  &__row,
  &__docs {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__text,
  &__copyright {
    margin: 0 auto rem(32px);
    font-size: rem(12px);
    line-height: rem(18px);
    text-align: center;
    text-transform: none;
    font-family: $font-reg;
    color: rgba(228, 201, 180, 0.7);
    p {
      margin-bottom: rem(17px);
    }
    a {
      white-space: nowrap;
    }
  }
  &__logo {
    display: block;
    margin: auto;
    height: rem(80px);
  }
  &__col {
    width: 100%;
  }
  &__copyright {
    margin-top: rem(12px);
  }
  &-nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: rem(30px);
    &__item {
      color: #fff;
      font-size: rem(14px);
      line-height: 1;
      &:not(:last-child) {
        margin-bottom: rem(32px);
      }
    }
  }

  @media (orientation: landscape) {
    padding: rem(145px) 0 rem(210px);
    text-align: left;
    font-size: rem(16px);
    .container {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &__content {
      padding-top: 0;
      background: none;
    }
    &__row {
      flex-direction: row;
      justify-content: space-between;
    }
    &__copyright {
      margin-top: 0;
      text-align: left;
      font-size: rem(16px);
    }
    &__text {
      width: 100%;
      margin: 0 auto rem(36px);
      font-size: rem(16px);
      line-height: rem(24px);
      p {
        margin-bottom: rem(15px);
      }
    }
    &__logo {
      height: rem(110px);
    }
    &-nav {
      margin: auto;
      margin-bottom: rem(45px);
      flex-direction: row;
      justify-content: space-between;
      &__item {
        margin: 0;
        font-size: rem(18px);
        border-bottom: rem(2px) solid transparent;
        transition: 0.4s;
        cursor: pointer;
        &:hover {
          border-color: #fff;
        }
        &:not(:last-child) {
          margin-bottom: rem(0px);
        }
      }
    }
    &__col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &:last-of-type {
        flex: 0 0 58%;
      }
    }
  }
}
</style>
