<template>
  <Modal
    name="success_feedback"
    :success="true"
    title="Спасибо!"
    @closeModal="closeModal"
    @before-open="beforeOpen"
  >
    <template v-slot:content>
      <div class="success_modal">
        <p class="modal__text success_modal__text">
          Ваше сообщение отправлено. Мы пришлем ответ на почту {{ mail }} в
          ближайшее время.
        </p>
        <router-link
          @click.native="closeModal('success_feedback')"
          to="/"
          class="btn btn--bordered btn--lg"
          >На главную</router-link
        >
        <div class="success_modal__footer"></div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      mail: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(e) {
      this.mail = e.params.mail;
    },
  },
};
</script>

<style lang="sass" scoped>
.btn
  margin: 0 auto
</style>
