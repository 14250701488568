<template>
  <form>
    <inputText
      label="Новый пароль"
      type="password"
      maxlength="24"
      :field="newPassword"
      @input="newPassword = $event"
      :class="{
        error: validationStatus($v.newPassword) || errorMessage.newPassword,
      }"
      :error="
        (!$v.newPassword.required && $v.newPassword.$error
          ? 'Обязательное поле'
          : !$v.newPassword.minLength && $v.newPassword.$error
          ? 'Минимум 6 символов'
          : !$v.newPassword.maxLength && $v.newPassword.$error
          ? 'Максимум 8 символа'
          : '') || errorMessage.newPassword
      "
    />

    <div class="change_password-footer">
      <button
        type="button"
        class="btn btn--primary btn--loader-icon w-100"
        @click="submit()"
        :class="{ loading: submitStatus == 'PENDING' }"
      >
        Отправить
        <img
          src="../../assets/images/icons/spinner.svg"
          class="loader"
          alt=""
        />
        <img
          class="btn__icon"
          src="../../assets/images/icons/arrow_1.svg"
          alt=""
        />
      </button>
    </div>
  </form>
</template>

<script>
import inputText from "@/components/form/inputText.vue";

import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "RecoverForm",
  props: ["token"],
  data() {
    return {
      ready: false,
      newPassword: null,
      repPassword: null,
      errorMessage: {
        oldPassword: null,
        newPassword: null,
        repPassword: null,
      },

      submitStatus: null,
    };
  },

  validations: {
    newPassword: {
      required,
      minLength: minLength(6),
      maxLength: maxLength(24),
    },
  },

  methods: {
    errorReset() {
      this.$v.$reset();

      this.errorMessage = {
        oldPassword: null,
        newPassword: null,
        repPassword: null,
      };
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },
    submit() {
      this.$v.$touch();

      if (this.$v.$pendding || this.$v.$error) return;

      if (this.submitStatus !== "PENDING") {
        this.submitStatus = "PENDING";

        this.$store
          .dispatch("SetPassword", {
            password: this.newPassword,
            token: this.token,
          })
          .then((r) => {
            if (!r.result) {
              this.submitStatus = null;

              let fieldsError = null;

              if (r.error && r.error.old_password) {
                [this.errorMessage.oldPassword] = r.error.old_password;
                fieldsError = true;
              }
              if (r.error && r.error.password) {
                [this.errorMessage.newPassword] = r.error.password;
                fieldsError = true;
              }
              if (r.error && r.error.passR) {
                [this.errorMessage.token] = r.error.passR;
                fieldsError = true;
              }

              if (r.error && !fieldsError) {
                this.$modal.show("common_error", {
                  text: "Что-то пошло не так! " + r.error,
                });
              }
            } else {
              this.submitStatus = null;
              this.$store.commit("SET_TOKEN", r.data.auth_key);
              this.$modal.hide("change_password");
              this.$modal.show("common_success", {
                title: "изменение пароля",
                text: "ваш пароль изменен",
              });
              this.$store.dispatch("GetProfile").then((res) => {
                if (!res.result) {
                  this.$router.push("/");
                } else {
                  this.$root.$emit("onLogined");
                  this.$router.push({ name: "Profile" });
                }
              });
            }
          })
          .catch(() => {
            this.submitStatus = null;
          });
      }
    },
  },
  mounted() {},
  components: {
    inputText,
  },
};
</script>
<style lang="scss">
.change_password-footer {
  margin-top: rem(40px);
  .btn {
    width: 100%;
  }
}
</style>
