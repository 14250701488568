<template>
  <section class="hero section" id="home">
    <div class="container">
      <div class="hero__row">
        <div class="hero__content">
          <div class="hero__suptitle">
            с&nbsp;24&nbsp;сентября по&nbsp;29&nbsp;октября 2024 года
          </div>
          <h1 class="hero__title">
            живите <br>с&nbsp;удовольствием <br>в&nbsp;ритме jardin
          </h1>
          <img
            src="../assets/images/hero/products-desk.png"
            alt=""
            class="hero__products visible-xs"
          />
          <button
            @click="setMetrika('register_receipt_1_screen'), toCheckUpload()"
            class="btn btn--primary btn--icon visible-xs"
          >
            <span>Зарегистрировать чек</span>
            <img
              class="btn__icon"
              src="../assets/images/icons/arrow_1.svg"
              alt=""
            />
          </button>
          <!-- <div @click="openInNewTab()" class="btn btn--primary btn--icon">
            <span>ПЕРЕЙТИ В ЧАТ-БОТ</span>
            <img class="btn__icon" src="../assets/images/icons/tg.svg" alt="" />
          </div> -->
        </div>

          <img
      src="../assets/images/hero/products-desk.png"
      alt=""
      class="hero__products hidden-xs"
    />
      </div>
    </div>

  </section>
</template>

<script>
export default {
  components: {},
  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
    openInNewTab() {
      this.setMetrika("bot");
    },
  },
  mounted() {
    // this.toCheckUpload()
  },
};
</script>
<style lang="scss" scoped>
.hero {
  position: relative;
  // height: calc(var(--vh, 1vh) * 100);
  // padding-bottom: rem(100px);
  padding-top: 0;
  z-index: 9;
  display: flex;
  align-items: flex-start;
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 107.5);
    background: url("../assets/images/hero/hero-mob.png") no-repeat center
      top;
    background-size: 100%;
  }

  .container {
    position: relative;
    padding-top: calc(var(--vh, 1vh) * 15.3);
    padding-bottom: rem(0px);
    height: 100%;
  }
  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  &__row {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: auto;
    flex-direction: column;
  }
  &__col {
    position: relative;
  }
  &__products {
    width: auto;
    height: calc(var(--vh, 1vh) * 40);
    margin-left: auto;
    margin-right: rem(50px);
    object-fit: contain;
  }
  &__suptitle {
    margin-bottom: calc(var(--vh, 1vh) * 2.8);
    text-transform: none;
    font-family: $font-reg;
    font-size: rem(16px);
    letter-spacing: 0.04em;
    text-shadow: 0px rem(4px) rem(4px) rgba(0, 0, 0, 0.25);
  }
  &__title {
    margin-bottom: calc(var(--vh, 1vh) * 2.8);
    font-family: $font-bold;
    color: #FFF4DE;
    font-size: rem(24px);
    line-height: rem(31px);
  }
  .btn {
    flex-shrink: 0;
    order: 1;
    margin-top: rem(45px);
    width: 86%;
  }

  @media (orientation: portrait) {
    background-size: cover;
  }

  @media (orientation: landscape) {
    // height: calc(var(--vh, 1vh) * 74.3);
    padding-top: 0;
    background: none;
    padding-bottom: 0;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(var(--vh, 1vh) * 100);
      background: url("../assets/images/hero/hero.png") no-repeat center top;
      background-size: auto 100%;
    }
    .container {
      padding-bottom: 0;
      padding-top: vh(111px);
      display: flex;
      height: 100%;
      align-items: center;
    }
    &__content {
      margin: 0;
      height: auto;
      text-align: left;
      align-items: flex-start;
    }
    &__row {
      max-width: 100%;
      flex-direction: row;
      align-items: center;
    }
    &__products {
      height: vh(560px);
      margin-left: auto;
      margin-right: rem(160px);
    }
    &__suptitle {
      margin-bottom: vh(47px);
      font-size: vh(26px);
      line-height: 1;
      letter-spacing: 0.045em;
    }
    &__title {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: vh(70px);
      line-height: vh(90px);
    }
    .btn {
      width: auto;
    }
  }
  @media (max-width: $xlg) {
  }
}
</style>
