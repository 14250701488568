<template>
  <label class="form-file">
    <input
      type="file"
      class="form-file__input"
      @input="(e) => submitMedia(e.target.files)"
    />
    <div class="form-file__custom">
      <span class="form-file__caption">
        <img src="../../assets/images/icons/add_file.svg" alt="" />
        <span v-html="label || 'изображение'"></span>
      </span>
    </div>
    <div class="form-file__chosen" v-if="chosenFileName[0]">
      {{ chosenFileName[0].name }}
      <div class="form-file__del" @click.prevent="deleteFile">
        <svg
          width="10"
          height="11"
          viewBox="0 0 10 11"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 1.5L9 9.5M9 1.5L1 9.5"
            stroke="white"
            stroke-width="1.5"
          />
        </svg>
      </div>
    </div>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
    },
    chosenFileName: {
      type: [FileList, Object],
    },
  },
  data: function () {
    return {
      // chosenFileName: ""
    };
  },
  methods: {
    submitMedia(e) {
      this.$emit("submitMedia", e);
    },
    deleteFile() {
      this.$emit("deleteFile");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-file {
  display: block;
  margin: rem(14px) 0 rem(14px);
  &__input {
    position: absolute;
    left: -9999px;
    opacity: 0;
    visibility: hidden;
  }

  &__custom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__caption {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #fff;
    font-size: rem(14px);
    font-family: $font-reg;
    img {
      width: rem(40px);
      margin-right: rem(10px);
    }
  }

  &__btn {
    width: rem(100px);
    height: rem(44px);
    margin: 0;
    line-height: 40px;
    padding: 0 28px;
    font-size: rem(18px);
  }

  &__chosen {
    position: relative;
    margin-top: rem(10px);
    padding: rem(14px) rem(35px) rem(14px) rem(18px);
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: none;
    font-family: $font-reg;
    text-overflow: ellipsis;
    background: #a47260;
    border-radius: rem(14px);
    font-size: rem(16px);
  }

  &__del {
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: rem(18px);
    display: flex;
    transform: translateY(-50%);
    width: rem(18px);
    height: rem(18px);
    border-radius: 50%;
    opacity: 0.3;
    svg {
      margin: auto;
    }
    &:hover {
      opacity: 1;
    }
  }
  @media (orientation: landscape) {
    margin: rem(13px) 0 rem(30px);
    &__caption {
      font-size: rem(14px);
      img {
        width: rem(50px);
        margin-right: rem(16px);
      }
    }
  }
}
</style>
