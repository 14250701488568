<template>
  <Modal
    name="common_success"
    size="lg"
    :success="true"
    :title="title"
    :successIcon="successIcon"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <p class="modal__subtitle" v-if="text" v-html="text"></p>
      </div>
      <button
        v-if="btnClose"
        @click="closeModal('common_success')"
        class="btn btn--primary btn--loader-icon w-100"
      >
        <span>Хорошо</span>
        <img
          class="btn__icon"
          src="../../assets/images/icons/arrow_1.svg"
          alt=""
        />
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      title: "Спасибо!",
      btnClose: false,
      successIcon: false,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      this.text = event.params.text;
      this.successIcon = event.params.successIcon;
      if (event.params.title) {
        this.title = event.params.title || "Спасибо!";
      }
      this.btnClose = event.params.btnClose;
    },
  },
};
</script>
<style lang="scss">
.success_modal-btn {
  margin-top: rem(15px);
}
</style>
