<template>
  <Modal name="image" @closeModal="closeModal" size="lg">
    <template v-slot:content>
      <div class="modal__image" v-if="src">
        <img :src="src" alt="" />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import axios from "axios";
export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      src: null,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.src = event.params.src;
    },
  },
};
</script>
