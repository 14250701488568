<template>
  <Modal name="video" @closeModal="closeModal" size="sm" classes="">
    <template v-slot:content>
      <div class="modal__iframe">
        <video
          :src="`${publicPath}video/${count}.mp4`"
          playsinline
          controls
          id="video"
        ></video>
        <img
          v-if="firstPlay"
          class="modal__iframe-preview"
          :src="`${publicPath}video/preview/${count}.jpg`"
          alt=""
        />
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      src: null,
      count: null,
      firstPlay: true,
      hideNav: false,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.src = event.params.src;
      this.count = event.params.count;
      this.hideNav = false;
      this.firstPlay = true;
    },
    prev() {
      let i = this.count;
      this.gtmEvent("", "stories", "story_prev", i);
      if (i > 1 && i < 7) {
        this.count--;
      }
      this.hideNav = false;
      this.firstPlay = true;
    },
    next() {
      let i = this.count;
      this.gtmEvent("", "stories", "story_next", i);
      if (i < 7) {
        this.count++;
      }
      this.hideNav = false;
      this.firstPlay = true;
    },
    videoIsFinished(e) {
      let arr = localStorage.getItem("stories");
      if (!arr) {
        arr = this.count;
      }
      console.log(arr);
      if (arr && !arr.toString().includes(this.count)) {
        arr = arr += `, ${this.count}`;
      }
      localStorage.setItem("stories", arr);
      this.$store.commit("SET_STORIES_COUNT", arr);
      if (!this.$store.getters.user) {
        return;
      }
      this.$store
        .dispatch("Story", { id: this.count })
        .then((r) => {
          if (!r.result) {
            if (r.error) {
              this.$modal.show("common_error", {
                text: "Что-то пошло не так! " + r.error,
              });
            }
          }
        })
        .catch(() => {});
      this.gtmEvent("", "stories", "story_end", this.count);
    },
    playVideo() {
      const $this = this;
      this.hideNav = !this.hideNav;
      const video = document.getElementById("video");
      if (this.hideNav) {
        this.firstPlay = false;
        video.play();
        this.gtmEvent("", "stories", "story_watch", this.count);
        video.addEventListener("ended", function (e) {
          $this.videoIsFinished();
        });
      } else {
        this.firstPlay = false;
        video.pause();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@media (max-width: 1023px) and (orientation: portrait)
  .modal__iframe
    video
      display: none

.modal__iframe-preview
  position: absolute
  top: 0
  left: 0
  width: 100vw
  height: 100dvh
  object-fit: cover
</style>
