<template>
  <Modal name="voting_popup" @closeModal="closeModal" size="max">
    <template v-slot:content>
      <div class="modal__image">
        <img :src="src" alt="" />
      </div>
      <div class="modal-voting" v-if="rating != -1">
        <button @click="sendVote()" class="btn btn--primary btn--icon">
          <span>голосовать <span class="hidden-xs">за это фото</span></span>
          <img
            class="btn__icon"
            src="../../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
        <div class="modal-rating" @click="sendVote()">
          <span>{{ rating }}</span>
          <svg
            width="34"
            height="32"
            viewBox="0 0 34 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.24642 29.8528C9.24642 30.8918 8.34263 31.7922 7.29981 31.7922H1.94662C0.903786 31.7922 0 30.8918 0 29.8528V14.684C0 13.645 0.903786 12.7446 1.94662 12.7446H7.29981C8.34263 12.7446 9.24642 13.645 9.24642 14.684V29.8528ZM31.9801 18.0087C32.6058 18.4935 32.9534 19.2554 32.9534 20.0866C32.9534 21.3333 32.0496 22.3723 30.9373 22.6494C31.563 23.1342 31.9106 23.8961 31.9106 24.7273C31.9106 25.974 31.0068 27.013 29.8249 27.29C30.4506 27.7749 30.7982 28.5368 30.7982 29.368C30.7982 30.8225 29.6164 32 28.1564 32L13.1397 31.8615C12.0968 31.8615 11.193 30.961 11.193 29.9221V14.684C11.193 12.2597 18.8405 7.8961 19.2576 5.47186C19.5357 3.94805 19.1881 0 20.2309 0C22.0385 0 24.3327 0.692642 24.3327 4.64069C24.3327 8.1039 22.0385 12.6753 22.0385 12.6753H31.3544C32.8144 12.6753 33.9962 13.8528 33.9962 15.3074C34.0658 16.6926 33.162 17.7316 31.9801 18.0087Z"
              fill="#F1C27A"
            />
            <path
              d="M9.24642 29.8528C9.24642 30.8918 8.34263 31.7922 7.29981 31.7922H1.94662C0.903786 31.7922 0 30.8918 0 29.8528V14.684C0 13.645 0.903786 12.7446 1.94662 12.7446H7.29981C8.34263 12.7446 9.24642 13.645 9.24642 14.684V29.8528Z"
              fill="#F1C27A"
            />
            <path
              d="M31.9801 18.0087C32.6058 18.4935 32.9534 19.2554 32.9534 20.0866C32.9534 21.3333 32.0496 22.3723 30.9373 22.6494C31.563 23.1342 31.9106 23.8961 31.9106 24.7273C31.9106 25.974 31.0068 27.013 29.8249 27.29C30.4506 27.7749 30.7982 28.5368 30.7982 29.368C30.7982 30.8225 29.6164 32 28.1564 32L13.1397 31.8615C12.0968 31.8615 11.193 30.961 11.193 29.9221V14.684C11.193 12.2597 18.8405 7.8961 19.2576 5.47186C19.5357 3.94805 19.1881 0 20.2309 0C22.0385 0 24.3327 0.692642 24.3327 4.64069C24.3327 8.1039 22.0385 12.6753 22.0385 12.6753H31.3544C32.8144 12.6753 33.9962 13.8528 33.9962 15.3074C34.0658 16.6926 33.162 17.7316 31.9801 18.0087Z"
              fill="#F1C27A"
            />
          </svg>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      src: null,
      rating: 0,
      id: 0,
    };
  },
  computed: {
    sortBy() {
      return this.$store.getters.voting.sort;
    },
    pageFromGetter() {
      return this.$store.getters.voting.page;
    },
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },
    beforeOpen(event) {
      this.src = event.params.src;
      this.rating = event.params.rating;
      this.id = event.params.id;
    },
    sendVote() {
      this.$modal.hide("voting_popup");
      if (this.$store.getters.user) {
        this.$store.dispatch("Vote", { photo_id: this.id }).then((response) => {
          if (response.result) {
            this.$modal.show("common_success", {
              title: "вы успешно проголосовали",
              btnClose: true,
              successIcon: true,
            });
            this.$store.dispatch("GetGallery", {
              page: this.pageFromGetter,
              order: this.sortBy,
            });
          } else {
            this.$modal.show("common_error", {
              title: response.error,
              code: response.code || 0,
            });
          }
        });
      } else {
        this.$modal.show("authorization");
      }
    },
  },
};
</script>
