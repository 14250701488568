<template>
  <section class="steps section" id="steps">
    <div class="steps__content">
      <div class="container">
        <div class="steps__list">
          <div v-for="(item, i) in steps" :key="i" class="steps__item">
            <div class="steps__item-index gradient-text">{{ i + 1 }}</div>
            <div class="steps__item-content">
              <div class="steps__item-title" v-html="item.title"></div>
              <div
                class="steps__item-text"
                v-if="item.text"
                v-html="item.text"
              ></div>
              <div
                class="steps__item-small"
                v-if="item.small"
                v-html="item.small"
              ></div>
              <button
                v-if="item.btn"
                @click="setMetrika('register_receipt_1_screen'), toCheckUpload()"
                class="btn btn--primary btn--icon hidden-xs"
              >
                <span>Зарегистрировать чек</span>
                <img
                  class="btn__icon"
                  src="../assets/images/icons/arrow_1.svg"
                  alt=""
                />
              </button>
            </div>
            <button
              v-if="item.btn"
              @click="setMetrika('register_receipt_1_screen'), toCheckUpload()"
              class="btn btn--primary btn--icon visible-xs"
            >
              <span>Зарегистрировать чек</span>
              <img
                class="btn__icon"
                src="../assets/images/icons/arrow_1.svg"
                alt=""
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      publicPath: process.env.BASE_URL,
      steps: [
        {
          title: "КУПИТЕ",
          text: "любой* кофе Jardin в&nbsp;сети <br>&laquo;Глобус&raquo;",
          small: "*кроме: Jardin Latte 18&nbsp;грамм,<br>Jardin Mocaccino 18&nbsp;грамм,<br>Jardin Cappuccino 18&nbsp;грамм,<br>Jardin Americano 18&nbsp;грамм",
        },
        {
          title: "зарегистрируйте",
          text: "чек на сайте",
          btn: true,
        },
        {
          title: "получИТЕ",
          text: "гарантированный приз <br>50₽ на&nbsp;телефон",
        },
        {
          title: "УЧАСТВУЙТЕ",
          text: "в розыгрыше призов",
        },
      ],
    };
  },
  methods: {
    showModal(name) {
      this.$modal.show(name);
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.steps {
  padding-top: rem(66px);
  z-index: 10;
  &__list {
  }
  &__item {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    &:not(:last-of-type) {
      margin-bottom: rem(39px);
    }
    &-index {
      position: relative;
      flex-shrink: 0;
      width: rem(90px);
      margin: rem(-5px) 0 0;
      font-size: rem(80px);
      text-align: center;
      line-height: 1;
    }
    &:first-of-type {
      .steps__item-index::before {
        display: none;
      }
    }
    &:last-of-type {
      .steps__item-index::after {
        display: none;
      }
    }
    &-title {
      width: 100%;
      font-size: rem(20px);
      line-height: rem(24px);
      margin-bottom: rem(6px);
    }
    &-text {
      margin-bottom: rem(7px);
      font-family: $font-reg;
      font-size: rem(18px);
      line-height: rem(21px);
      text-transform: none;
      color: $gold;
    }
    &-small {
      text-transform: none;
      color: $gold;
      font-family: $font-reg;
      line-height: rem(12px);
      font-size: rem(10px);
    }
    .btn {
      margin: rem(8px) auto rem(10px);
    }
  }
  @media (orientation: landscape) {
    padding-top: vh(22px);
    padding-bottom: rem(80px);
    background: none;
    z-index: 10;

    &__list {
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    &__item {
      // flex: 0 0 25%;
      margin-bottom: 0 !important;
      flex-wrap: nowrap;
      &::after {
        content: "";
        margin: rem(34px) rem(34px);
        display: block;
        width: rem(60px);
        height: 2px;
        background: #E4C9B5;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
      &-index {
        margin-right: vh(28px);
        width: auto;
        margin-top: 0;
        font-size: vh(90px);
        line-height: 1;
      }
      &-title {
        margin-bottom: rem(6px);
        font-size: rem(32px);
        line-height: rem(38px);
      }
      &-text {
        font-size: rem(22px);
        line-height: em(26px);
      }
      &-small {
        margin-top: rem(5px);
        font-size: rem(12px);
        line-height: em(14px);
      }
      .btn {
        margin: rem(28px) 0 0;
      }
    }
  }
}
@keyframes dash {
  from {
    stroke-dashoffset: 3000;
  }
  to {
    stroke-dashoffset: 0;
  }
}
</style>
