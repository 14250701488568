<template>
  <Modal
    name="common_error"
    :errorIcon="true"
    :title="title"
    size="lg"
    @closeModal="closeModal"
  >
    <template v-slot:content>
      <div class="success_modal">
        <p v-if="text" class="modal__subtitle" v-html="text"></p>

        <ul v-if="list" class="common-error-list">
          <li v-for="(li, i) in list" :key="i" class="modal__text">{{ li }}</li>
        </ul>
        <button
          v-if="!code"
          @click="closeModal('common_error')"
          class="btn btn--primary btn--loader-icon w-100"
        >
          <span>Закрыть</span>
          <img
            class="btn__icon"
            src="../../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
        <button
          v-else
          @click="closeModal('common_error'), toCheckUpload()"
          class="btn btn--primary btn--loader-icon w-100"
        >
          <span>загрузить чек</span>
          <img
            class="btn__icon"
            src="../../assets/images/icons/arrow_1.svg"
            alt=""
          />
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Modal,
  },
  data: function () {
    return {
      text: "",
      title: "",
      list: null,
      code: 0,
    };
  },
  methods: {
    closeModal(name) {
      this.$modal.hide(name);
    },

    beforeOpen(event) {
      console.log(event);
      this.title = event.params?.title || "Ошибка!";
      this.text = event.params?.text;
      this.list = event.params?.list || [];
      this.code = event.params?.code;
    },
  },
};
</script>
