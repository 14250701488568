<template>
  <section class="prizes section" id="prizes">
    <div class="container">
      <div class="prize prize--1">
        <div class="prize__title">гарантированный приз</div>
        <img
          src="../assets/images/prizes/prize-1.png"
          alt=""
          class="prize__img"
        />
      </div>
      <div class="prizes__row">
        <div class="prize prize--2">
          <div class="prize__title">ЕЖЕНЕДЕЛЬНЫЙ <br />РОЗЫГРЫШ</div>
          <img
            src="../assets/images/prizes/prize-2.png"
            alt=""
            class="prize__img"
          />
        </div>
        <div class="prize prize--3">
          <div class="prize__title">СПЕЦИАЛЬНЫЙ <br />РОЗЫГРЫш<sup>**</sup></div>
          <img
            src="../assets/images/prizes/prize-3.png"
            alt=""
            class="prize__img"
          />
        </div>
      </div>
      <div class="prizes__note">**розыгрыш среди покупателей 2&nbsp;или более упаковок кофе Jardin за&nbsp;весь период акции</div>
      <div class="prizes__row">
        <div class="prize prize--4">
          <div class="prize__title">главный приз</div>
          <img
            src="../assets/images/prizes/prize-4.png"
            alt=""
            class="prize__img"
          />
          <button @click="toCheckUpload(), setMetrika('register_receipt_2_screen')" class="btn btn--primary btn--icon">
            <span>Зарегистрировать чек</span>
            <img
              class="btn__icon"
              src="../assets/images/icons/arrow_1.svg"
              alt=""
            />
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.prizes {
  position: relative;
  padding-top: rem(65px);
  padding-bottom: rem(62px);
  z-index: 10;
  @media (orientation: landscape) {
    padding-top: rem(110px);
    padding-bottom: rem(40px);
  }
  &__row {
    margin: auto;
    display: flex;
    flex-direction: column;
    @media (orientation: landscape) {
      width: 72%;
      flex-direction: row;
      justify-content: center;
    }
  }
  &__note {
    margin-bottom: rem(17px);
    color: $yellow;
    text-align: center;
    text-transform: none;
    font-size: rem(12px);
    line-height: 1.1;
    @media (orientation: landscape) {
      margin-top: rem(63px);
      margin-bottom: 0;
      font-size: rem(16px);
    }
  }
}
.prize {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &--1 {
    margin-bottom: rem(63px);
    @media (orientation: landscape) {
      margin-bottom: rem(75px);
    }
  }
  &--2 {
    margin-bottom: rem(67px);
    @media (orientation: landscape) {
      width: 50%;
      margin-bottom: 0;
    }
  }
  &--3 {
    margin-bottom: rem(15px);
    @media (orientation: landscape) {
      width: 50%;
      margin-bottom: 0;
    }
  }
  &--4 {
    @media (orientation: landscape) {
      margin-top: rem(100px);
    }
  }
  &__title {
    width: 90%;
    margin: auto;
    margin-bottom: rem(30px);
    font-size: rem(26px);
    line-height: rem(31px);
    color: $light;
    text-align: center;
    sup {
      font-family: Arial;
    }
    .prize--2 & {
      margin-bottom: rem(35px);
    }
    .prize--3 & {
      margin-bottom: rem(33px);
    }
    .prize--4 & {
      margin-bottom: rem(34px);
    }
    @media (orientation: landscape) {
      width: 100%;
      margin-bottom: rem(-5px);
      font-size: rem(50px);
      line-height: rem(65px);
      .prize--1 & {
        margin-bottom: rem(35px);
      }
      .prize--2 & {
        margin-bottom: rem(33px);
      }
      .prize--3 & {
        margin-bottom: rem(33px);
      }
      .prize--4 & {
        margin-bottom: rem(-5px);
      }
    }
  }
  &__subtitle {
    margin-bottom: rem(10px);
    color: $yellow;
    font-size: rem(20px);
    line-height: rem(24px);
    @media (orientation: landscape) {
      margin-bottom: rem(12px);
      font-size: rem(24px);
    }
  }

  &__img {
    max-width: 100%;
    .prize--1 & {
      height: rem(275px);
    }
    .prize--2 & {
      height: rem(200px);
    }
    .prize--3 & {
      height: rem(200px);
    }
    .prize--4 & {
      height: rem(230px);
    }
    @media (orientation: landscape) {
      height: rem(412px);
      margin-bottom: 0;
      .prize--1 & {
        height: rem(350px);
        margin-bottom: rem(27px);
      }
      .prize--2 &,
      .prize--3 & {
        height: rem(280px);
        margin-bottom: 0;
      }
      .prize--4 & {
        height: rem(420px);
        margin-top: rem(70px);
        margin-bottom: rem(105px);
      }
    }
  }
  .btn {
    margin-top: rem(69px);
    @media (orientation: landscape) {
      margin-top: 0;
    }
  }
}
</style>
